import React from 'react';
import Layout from '../components/Layout';

import upcoming from '../content/shows_upcoming';
import past from '../content/shows_past';
import "../components/styles/shows.css";

const tabData = {
    upcoming:   upcoming,
    past:       past
}

class ShowsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            activeTab: "upcoming"
        }
    }

    render() {
        const showData = tabData[this.state.activeTab];

        const upcomingClass = "shows-tab" + (this.state.activeTab === "upcoming" ? " active-tab" : "");
        const pastClass = "shows-tab" + (this.state.activeTab === "past" ? " active-tab" : "");


        return (
            <Layout>
                <div className="shows-page-wrapper">
                   <div className="shows-tab-wrapper">
                        <div 
                            className={upcomingClass}
                            onClick={() => this.setState({activeTab: "upcoming"})}
                        >
                            upcoming
                        </div>
                        <div 
                            className={pastClass}
                            onClick={() => this.setState({activeTab: "past"})}
                        >
                            past
                        </div>
                    </div>
                    <div className="shows-wrapper">
                        {showData.shows.map(show => <Show {...show} key={show.venue + ":" + show.date}/>)}
                    </div>
                </div>
            </Layout>
        );
    }
}

const Show = props => (
    <div className="show-wrapper">
        <p className="show-date">{props.date}</p>
        <div className="show-second-column">
            <p className="show-venue">{props.venue}</p>
            <p className="show-location">{props.location}</p>
        </div>
        <div className="show-links">
            {props.eventLink && <a target="_blank" rel="noopener noreferrer" href={props.eventLink}><p className="event-link">EVENT</p></a>}
            {props.ticketsLink && <a target="_blank" rel="noopener noreferrer" href={props.ticketsLink}><p className="tickets-link">TICKETS</p></a>}
        </div>
    </div>
)

export default ShowsPage;
