const content = {
    shows: [
        ,
        {
            venue: "La Sotterenea",
            location: "Montreal, QC",
            date: "November 30, 2023",
        },
        {
            venue: "Carlton Cinema (Ghostwriters film screening)",
            location: "Toronto,ON",
            date: "November 4, 2023",
        },
         {
            venue: "Queen St. Fare",
            location: "Ottawa, ON",
            date: "November 3, 2023",
        },
         {
            venue: "L'Escogriffe Bar Spectacle",
            location: "Montreal, QC",
            date: "August 1, 2023",
        },
        {
            venue: "Live on Elgin",
            location: "Ottawa, ON",
            date: "July 2, 2023",
        },
        {
            venue: "Hémisphère Gauche",
            location: "Montreal, QC",
            date: "June 30, 2023",
            
        },
        {
            venue: "L'Escogriffe Bar Spectacle",
            location: "Montreal, QC",
            date: "February 1, 2023",
        },
        {
            venue: "Rainbow Bistro",
            location: "Ottawa, ON",
            date: "January 27, 2023",
            
        },
         {
            venue: "Sneaky Dee's",
            location: "Toronto, ON",
            date: "January 20, 2023",
        },
        {
            venue: "Live On Elgin",
            location: "Ottawa, ON",
            date: "July 23, 2022",
        },     
        {
            venue: "Supermarket",
            location: "Toronto, ON",
            date: "May 22, 2022",
        },     
        {
            venue: "Club SAW w/ The Honest Heart Collective",
            location: "Ottawa, ON",
            date: "October 29, 2021",
        },     
        {
            venue: "The Mansion",
            location: "Kingston, ON",
            date: "October 21, 2021",
        },     
        {
            venue: "Club SAW",
            location: "Ottawa, ON",
            date: "September 25, 2021",
        },     
        {
            venue: "Instagram Live (moved online because of COVID-19)",
            location: "Anywhere",
            date: "March 14, 2020",
        },     
        {
            venue: "Cinqhole",
            location: "Ottawa, ON",
            date: "February 6, 2020",
        },     
        {
            venue: "House of Targ w/ Rich Aucoin",
            location: "Ottawa, ON",
            date: "November 23, 2019",
        },     
        {
            venue: "The Cavern",
            location: "Toronto, ON",
            date: "November 9, 2019",
        }, 
        {
            venue: "Duke's Bohemian Grove Bar",
            location: "Buffalo, NY",
            date: "October 19, 2019",
        },
        {
            venue: "Muchmore's",
            location: "Brooklyn, NY",
            date: "October 15, 2019",
        },     
        {
            venue: "The Lilypad",
            location: "Cambridge, MA",
            date: "October 13, 2019",
        },    
        {
            venue: "Monopole",
            location: "Plattsburgh, NY",
            date: "October 12, 2019",
        },
        {
            venue: "The Low Beat",
            location: "Albany, NY",
            date: "October 11, 2019",
        },     
        {
            venue: "Marvest",
            location: "Ottawa, ON",
            date: "September 13, 2019",
        },     
        {
            venue: "The 27 Club",
            location: "Ottawa, ON",
            date: "August 17, 2019",
        },
        {
            venue: "Live on Elgin",
            location: "Ottawa, ON",
            date: "August 2, 2019",
        },
        {
            venue: "The 27 Club (Ancestor EP Release Show)",
            location: "Ottawa, ON",
            date: "May 3, 2019",
        },
        {
            venue: "The 27 Club w/ Zola Jesus",
            location: "Ottawa, ON",
            date: "September 25, 2018",
        },
        {
            venue: "Bar Robo",
            location: "Ottawa, ON",
            date: "August 7, 2018",
        },
        {
            venue: "Makerspace North w/ Yung Heazy & Everett Bird",
            location: "Ottawa, ON",
            date: "May 31, 2018",
        },
        {
            venue: "Pressed",
            location: "Ottawa, ON",
            date: "April 5, 2018",
        },
        {
            venue: "The 27 Club w/ Geoffroy",
            location: "Ottawa, ON",
            date: "March 23, 2018",
        },
        {
            venue: "Avant Garde Bar",
            location: "Ottawa, ON",
            date: "January 27, 2018",
        },
        {
            venue: "The 27 Club w/ The Elwins & Fast Romantics",
            location: "Ottawa, ON",
            date: "November 25, 2017",
        },
        {
            venue: "House of Targ w/ David Liebe Hart",
            location: "Ottawa, ON",
            date: "September 26, 2017",
        },
        {
            venue: "House of Targ w/ Moon King",
            location: "Ottawa, ON",
            date: "September 13, 2017",
        },
        {
            venue: "Smiling Buddha",
            location: "Toronto, ON",
            date: "August 25, 2017",
        },
        {
            venue: "RBC Ottawa Bluesfest",
            location: "Ottawa, ON",
            date: "July 13, 2017",
        },
        {
            venue: "Black Squirrel",
            location: "Ottawa, ON",
            date: "May 21, 2017",
        },
        {
            venue: "Wailing Wolfe Festival",
            location: "Kingston, ON",
            date: "May 20, 2017",
        },
        {
            venue: "Moonshine Cafe",
            location: "Oakville, ON",
            date: "May 19, 2017",
        },
        {
            venue: "Zaphod's w/ Holy Fuck (JUNOfest)",
            location: "Ottawa, ON",
            date: "April 1, 2017",
        },
        {
            venue: "Pressed",
            location: "Ottawa, ON",
            date: "Mar 1, 2017",
        },
        {
            venue: "Black Squirrel",
            location: "Ottawa, ON",
            date: "Jan 13, 2017",
        },
        {
            venue: "Babylon Nightclub w/ Darcys & Bear Mountain",
            location: "Ottawa, ON",
            date: "November 27, 2016",
        },
        {
            venue: "Zaphod's w/ Paper Lions",
            location: "Ottawa, ON",
            date: "October 12, 2016",
        },
        {
            venue: "Zaphod's w/ Van Damsel",
            location: "Ottawa, ON",
            date: "May 19, 2016",
        },
        {
            venue: "House of Targ w/ Library Voices",
            location: "Ottawa, ON",
            date: "March 1, 2016",
        },
        {
            venue: "Black Squirrel",
            location: "Ottawa, ON",
            date: "December 4, 2015",
        },
        {
            venue: "Live on Elgin",
            location: "Ottawa, ON",
            date: "October 4, 2015",
        },
    ]
};

export default content;
